<template>
  <div class="pageContol listWrap templateList addClassCtr">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">考试计划</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">{{
            $route.query.stu == "add" ? "新增学员" : "学员详情"
          }}</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div v-show="$route.query.stu == 'detail'">
              <h3 style="padding-bottom: 20px">考试信息</h3>
              <el-form
                :model="ruleForm"
                ref="ruleForm"
                label-width="120px"
                disabled
              >
                <el-form-item label="鉴定考试工种：" prop="examOccupationId">
                  <el-select
                    v-model="ruleForm.compOccupationId"
                    placeholder="请选择"
                    clearable
                    filterable
                    size="small"
                  >
                    <el-option
                      v-for="item in occupation"
                      :key="item.compOccupationId"
                      :label="item.showName"
                      :value="item.compOccupationId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="考试级别：" prop="examLevel">
                  <el-select
                    v-model="ruleForm.examLevel"
                    placeholder="请选择"
                    size="small"
                    disabled
                  >
                    <el-option
                      v-for="item in ExaminationLevelList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              
              </el-form>
            </div>

            <h3
              style="padding-bottom: 20px"
              v-if="studentInformation == 'first' || $route.query.stu == 'edit'"
            >
              个人信息-必填部分
            </h3>
            <el-form
              :model="evaluateUserForm"
              :rules="evaluateUserRules"
              ref="evaluateUserForm"
              label-width="120px"
              :disabled="detailDisabled"
            >
              <div v-show="studentInformationfirst">
                <el-form-item label="姓名：" prop="userName">
                  <el-input
                    v-model="evaluateUserForm.userName"
                    placeholder="请输入姓名"
                    maxlength="100"
                    size="small"
                  ></el-input>
                </el-form-item>
                <el-form-item label="证件类型：" prop="idType">
                  <el-select
                    v-model="evaluateUserForm.idType"
                    placeholder="请选择"
                    clearable
                    filterable
                    size="small"
                    :disabled="$route.query.stu == 'edit'"
                  >
                    <el-option
                      v-for="item in evaluateIdTypeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="证件号：" prop="idcard">
                  <el-input
                    v-model="evaluateUserForm.idcard"
                    clearable
                    :disabled="$route.query.stu == 'edit'"
                    @blur="cardBlur"
                    placeholder="请输入证件号"
                    size="small"
                  ></el-input>
                </el-form-item>

                <el-form-item label="文化程度：" prop="educationLevel">
                  <el-select
                    v-model="evaluateUserForm.educationLevel"
                    placeholder="请选择"
                    clearable
                    filterable
                    size="small"
                  >
                    <el-option
                      v-for="item in educationList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="所在单位：" prop="workUnit">
                  <el-input
                    v-model="evaluateUserForm.workUnit"
                    placeholder="请输入所在单位"
                    maxlength="50"
                    size="small"
                  ></el-input>
                </el-form-item>
                <el-form-item label="手机号码：" prop="mobile">
                  <el-input
                    v-model="evaluateUserForm.mobile"
                    placeholder="请输入手机号码"
                    :disabled="$route.query.stu == 'edit'"
                    size="small"
                  ></el-input>
                </el-form-item>
                <el-form-item label="申报条件：" prop="conditionId">
                  <el-select
                    v-model="evaluateUserForm.conditionId"
                    placeholder="请选择"
                    clearable
                    filterable
                    value-key="conditionId"
                    size="small"
                    @change="conditionChange"
                  >
                    <el-option
                      v-for="item in conditionIdTypeList"
                      :key="item.conditionId"
                      :label="item.conditionName"
                      :value="item.conditionId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <h3
                style="padding-bottom: 20px"
                v-if="
                  studentInformation == 'second' || $route.query.stu == 'edit'
                "
              >
                个人信息-材料上传
              </h3>
              <div v-show="studentInformationsecond">
                <!-- <el-form
                :model="evaluateUserForm"
                :rules="evaluateUserRules"
                ref="evaluateUserForm"
                label-width="120px"
              > -->
                <el-form-item
                  v-for="(item, index) in evaluateMaterials"
                  :key="index"
                  :label="item.materialsName"
                >
                  <el-upload
                    :on-change="
                      (res, file) => {
                        return handleAvatarSuccess2(res, file, index);
                      }
                    "
                    :before-upload="beforeAvatarUpload2"
                    :http-request="$requestMine"
                    :show-file-list="false"
                    class="img-el-upload"
                    action
                    accept="image/png, image/gif, image/jpg, image/jpeg"
                  >
                    <el-image
                      :src="
                        item.materialsUrl || require('@/assets/develop.png')
                      "
                      fit="contain"
                      class="imgCenter"
                    ></el-image>
                  </el-upload>
                </el-form-item>
              </div>
            </el-form>

            <div class="bottom-btn">
              <!-- <el-button @click="cancle" class="bgc-bv">取消</el-button> -->
              <div v-if="$route.query.stu == 'add'">
                <el-button
                  @click="nextStep('up')"
                  class="bgc-bv"
                  v-show="studentInformation != 'first'"
                  >上一步</el-button
                >
                <el-button
                  @click="nextStep('down')"
                  class="bgc-bv"
                  v-show="studentInformation != 'second'"
                  >下一步</el-button
                >
                <!-- <el-button
                  @click="lookComplete()"
                  class="bgc-bv"
                  v-show="studentInformation == 'second'"
                  >预览完整信息</el-button
                > -->
                <el-button
                  @click="seveData()"
                  class="bgc-bv"
                  v-show="studentInformation == 'second'"
                  >保存</el-button
                >
              </div>
              <div v-else-if="$route.query.stu == 'detail'">
                <el-button @click="goHerf()" class="bgc-bv">返回</el-button>
                <el-button @click="reviewePlanUser('pass')" class="bgc-bv" v-show="evaluateUserForm.applyType == '10'"
                  >通过</el-button
                >
                <el-button @click="reviewePlanUser('noPass')" class="bgc-bv" v-show="evaluateUserForm.applyType == '10'"
                  >不通过</el-button
                >
              </div>
              <div v-else>
                <el-button @click="goHerf()" class="bgc-bv">返回</el-button>
                <el-button @click="seveData()" class="bgc-bv">保存</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 审核不通过 -->
    <el-dialog
      title="审核不通过"
      :visible.sync="dialogVisible"
      width="30%"
      @close="cancle"
    >
      <div>
        <el-input
          type="textarea"
          placeholder="请输入审核不通过原因"
          maxlength="1000"
          v-model="auditReason"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancle" class="bgc-bv">取 消</el-button>
        <el-button @click="sure()" class="bgc-bv">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import UploadPic from "@/mixins/UploadPic";
import DatePicker from "@/mixins/DatePicker";
import { mapGetters } from "vuex";
export default {
  name: "addClassCtr",
  components: {},
  mixins: [UploadPic, DatePicker],
  data() {
    var projectUserPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入正确的手机号码"));
      } else if (
        value &&
        !/^1[3456789]\d{9}$/.test(value) &&
        !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
      ) {
        callback(new Error("请输入正确的手机号码"));
      } else {
        callback();
      }
    };
    return {
      //基本设置
      ruleForm: {
        examOccupationId: "", //考试工种
        examLevel: "", //考试工种
        theoryTestTime: "", //理论考试时间
        examTheoryPlace: "", //理论考试地点
        practiceTestTime: "", //实操考试时间
        examPracticePlace: "", //实操考试地点
        comprehensiveReviewTime: "", //综合评审时间
        examReviewPlace: "", //综合评审地点
        examEndTime: "", //报名截止时间
        examPeople: "", //考试人数
      },
      rules: {
        examOccupationId: [
          {
            required: true,
            message: "请选择鉴定考试工种",
            trigger: "change",
          },
        ],
        examLevel: [
          {
            required: true,
            message: "请选择考试级别",
            trigger: "chaneg",
          },
        ],
        theoryTestTime: [
          {
            required: true,
            message: "请选择理论考试时间",
            trigger: "change",
          },
        ],
        examTheoryPlace: [
          {
            required: true,
            message: "请选择理论考试地点",
            trigger: "blur",
          },
        ],
        practiceTestTime: [
          {
            required: true,
            message: "请选择实操考试时间",
            trigger: "change",
          },
        ],
        examPracticePlace: [
          {
            required: true,
            message: "请选择实操考试地点",
            trigger: "blur",
          },
        ],
        examEndTime: [
          {
            required: true,
            message: "请选择报名截止时间",
            trigger: "change",
          },
        ],
        examPeople: [
          {
            required: true,
            message: "请输入考试人数",
            trigger: "blur",
          },
        ],
      },

      studentInformation: "first",
      studentInformationfirst: true,
      studentInformationsecond: false,
      //个人信息必填部分
      evaluateUserForm: {
        userName: "",
        idcard: "",
        mobile: "",
        idType: "",
        educationLevel: "",
        workUnit: "",
        conditionId: "",
      },
      evaluateUserRules: {
        userName: [
          { required: true, message: "请输入学员姓名", trigger: "blur" },
        ],
        idType: [
          { required: true, message: "请输入证件类型", trigger: "blur" },
        ],
        idcard: [{ required: true, message: "请输入证件号", trigger: "blur" }],

        educationLevel: [
          { required: true, message: "请选择文化程度", trigger: "change" },
        ],
        mobile: [
          { required: true, validator: projectUserPhone, trigger: "blur" },
        ],

        conditionId: [
          { required: true, message: "请选择申报条件", trigger: "change" },
        ],
        workUnit: [
          { required: true, message: "请输入所在单位", trigger: "blur" },
        ],
      },
      evaluateIdTypeList: [], //证件类型
      educationList: [], //文化程度
      conditionIdTypeList: [], //申报条件下拉数据
      evaluateMaterials: [],
      occupation: [],
      detailDisabled:false,
      //审核不通过
      dialogVisible:false,
      auditReason:'', //不通过原因
    };
  },
  created() {
    this.getCrowdtypeList();
    // this.getInformation(this.$route.query.planId);
    this.getOccupationConditionListDropDown(this.$route.query.planId);
    if (this.$route.query.stu == "edit") {
      this.getUserInfo(this.$route.query.planId, this.$route.query.planUserId);
      this.studentInformationfirst = true;
      this.studentInformationsecond = true;
    }
    if (this.$route.query.stu == "detail") {
      this.getOccupation()
      this.getUserInfo(this.$route.query.planId, this.$route.query.planUserId);
      this.detailDisabled = true;
      this.studentInformationfirst = true;
      this.studentInformationsecond = true;
      this.getInformation(this.$route.query.planId)
    }
  },
  methods: {
    /* 鉴定考试工种 */
    getOccupation() {
      this.$post("/biz/evaluate/company/occupation/own", {})
        .then((ret) => {
          this.occupation = ret.data.list || [];
        })
        .catch((err) => {
          return;
        });
    },
    /* 基本信息回显数据 */
    getInformation(planId) {
      this.$post("/biz/evaluate/plan/getInfo", { planId })
        .then((ret) => {
          let retData = ret.data;
          console.log(retData);
          this.ruleForm = {
            ...retData,
            examOccupationId: retData.examOccupationId,
            examLevel: retData.examLevel,
          };
        })
        .catch((err) => {
          return;
        });
    },
    /* 申报条件 -- 下拉 */
    getOccupationConditionListDropDown(planId) {
      this.$post(
        "/biz/evaluate/evaluateCompanyOccupationCondition/occupationConditionListDropDown",
        { planId }
      ).then((res) => {
        this.conditionIdTypeList = res.data || [];
      });
    },
    conditionChange(val) {
      this.conditionIdTypeList.map((el) => {
        if (el.conditionId == val) {
          this.evaluateMaterials = el.evaluateMaterials;
        }
      });
    },

    //下一步
    nextStep(stu) {
      if (stu == "down") {
        switch (this.studentInformation) {
          case "first":
            const el = this.$refs.evaluateUserForm;
            el.validate((valid) => {
              if (valid) {
                this.studentInformation = "second";
                this.studentInformationfirst = false;
                this.studentInformationsecond = true;
              }
            });

            break;
        }
      } else {
        switch (this.studentInformation) {
          case "second":
            this.studentInformation = "first";
            this.studentInformationfirst = true;
            this.studentInformationsecond = false;
            break;
         
        }
      }

      // if(this.studentInformation == 'first') {
      //     this.studentInformation == 'second'
      // } else if(this.studentInformation == 'second') {

      // }
    },

    //码值获取信息
    getCrowdtypeList() {
      const evaluateIdTypeList = this.$setDictionary(
        "EVALUATE_ID_TYPE",
        "list"
      );
      const evaluateEducationList = this.$setDictionary(
        "EDUCATION",
        "list"
      );

      const list = [],
        educationList = [];
      for (const key in evaluateIdTypeList) {
        list.push({
          value: key,
          label: evaluateIdTypeList[key],
        });
      }
      for (const key in evaluateEducationList) {
        educationList.push({
          value: key,
          label: evaluateEducationList[key],
        });
      }

      this.evaluateIdTypeList = list;
      this.educationList = educationList;
    },

    /* 机构名称 */
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.companyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.companyList = [];
      }
    },

    /**
     * 单位回显接口
     */
    getCompany(id) {
      this.$post("/sys/company/id", { compId: id }).then((res) => {
        // ;
        this.companyList = [res.data];
      });
    },
    seveData() {
      const el = this.$refs.evaluateUserForm;
      el.validate((valid) => {
        if (valid) {
          this.doAjax();
        }
      });
    },
    doAjax() {
      // this.isNumber(this.evaluateUserForm.evaluateCompId);
      let parmar = {
        userName: this.evaluateUserForm.userName, //姓名
        idType: this.evaluateUserForm.idType, //证件类型
        idcard: this.evaluateUserForm.idcard, //证件号
        educationLevel: this.evaluateUserForm.educationLevel, //文化程度
        workUnit: this.evaluateUserForm.workUnit, //所在单位
        mobile: this.evaluateUserForm.mobile, //手机号
        planId: this.$route.query.planId,
        conditionId: this.evaluateUserForm.conditionId,
      };
      parmar.evaluatePlanUserMaterialsList = this.evaluateMaterials;
      if (this.$route.query.planUserId) {
        parmar.planUserId = this.$route.query.planUserId;
      }
      this.$post(
        this.$route.query.stu != "edit"
          ? "/biz/evaluate/evaluatePlanUser/addPlanUserSingle"
          : "/biz/evaluate/evaluatePlanUser/updatePlanUser",
        parmar
      )
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            this.$router.push({
              path: "/web/appraisalInstitution/appraisalExaminationBox",
              query: {
                refresh: true,
                isAdd: true,
                active: "four",
              },
            });
          }
        })
        .catch((err) => {
          return;
        });
    },
    getUserInfo(planId, planUserId) {
      this.$post("/biz/evaluate/evaluatePlanUser/planUserInfo", {
        planId,
        planUserId,
      }).then((ret) => {
        console.log(ret);
        this.evaluateUserForm = {
          ...ret.data,
        };
        this.evaluateMaterials = ret.data.evaluatePlanUserMaterialsList;
      });
    },
    goHerf() {
      this.$router.back();
    },
    lookComplete() {
      this.dialogComplete = true;
    },

    //图片上传
    handleAvatarSuccess2(res, file, index) {
      let extension = res.name.substring(
        res.name.lastIndexOf(".") + 1,
        res.name.length
      );
      let formData = new FormData();
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("folder ", "CT_QUESTION_BANK");
      formData.append("isPublic ", true);
      this.$Postformat("/sys/file/upload", formData, 3000, true, 6)
        .then((result) => {
          // this.evaluateMaterials[index].materialsKey = result.data.fileKey || "";
          // this.evaluateMaterials[index].materialsUrl = result.data.fileUrl || "";
          this.$set(
            this.evaluateMaterials[index],
            "materialsUrl",
            result.data.fileUrl
          );
          this.$set(
            this.evaluateMaterials[index],
            "materialsKey",
            result.data.fileKey
          );
          console.log(this.evaluateMaterials);
        })
        .catch(() => {
          setTimeout(() => {
            that.$message({
              type: "warning",
              message: "上传失败",
            });
          }, 300);
        });
    },
    //审核
    reviewePlanUser(stu) {
      if (stu == 'noPass') {
        this.dialogVisible = true
      } else {
        let parmar = {
        auditState: '30',
        planId: this.$route.query.planId,
        planUserId: this.$route.query.planUserId,
      };
      this.$post("/biz/evaluate/evaluatePlanUser/reviewePlanUser", parmar).then(
        (res) => {
          if (res.status == "0") {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.goHerf();
          }
        }
      );
      }
    },
     //不通过原因
    sure() {
      if (!this.auditReason) {
        this.$message({
          message: "请输入审核不通过原因",
          type: "warning",
        });
        return false;
      }
      let parmar = {
        auditReason:this.auditReason,
        auditState: '40',
        planId: this.$route.query.planId,
        planUserId: this.$route.query.planUserId,
      };
      this.$post("/biz/evaluate/evaluatePlanUser/reviewePlanUser", parmar).then(
        (res) => {
          if (res.status == "0") {
            this.$message({
              message: res.message,
              type: "success",
            });
             this.dialogVisible = false;
            this.auditReason = "";
            this.goHerf();
          }
        }
      );
    
    },
    // 取消审核不用过弹框
    cancle() {
      this.dialogVisible = false;
      this.auditReason = "";
    },
  },
};
</script>
<style lang="less">
.el-textarea {
  .el-textarea__inner {
    resize: none;
  }
}
</style>
<style lang="less">
.addClassCtr {
  .el-form {
    .form-item {
      width: 60%;
    }
  }
  .bottom-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
